/* General styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  transition: background-color 0.3s, color 0.3s;
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #dee2e6;
  position: relative;
}

.app-header h1 {
  margin: 0;
  font-size: 1.5rem;
}

/* Light mode styles */
.light-mode {
  background-color: #f5f5f5;
  color: #000;
}

.light-mode .dropdown-toggle {
  background-color: #e0e0e0;
  color: #000;
}

.light-mode .dropdown-menu {
  background-color: #ffffff;
  color: #000;
  max-height: 100px;
  overflow-y: scroll;
}

.light-mode .nav-link.active {
  background-color: #ffffff;
  color: #495057;
}

.light-mode .app-header {
  background-color: #ffffff;
  color: #000;
  border-bottom: 1px solid #dee2e6;
}

.light-mode .btn-dark-mode {
  color: #000;
}

/* Dark mode styles */
.dark-mode {
  background-color: #121212;
  color: #ffffff;
}

.dark-mode .dropdown-toggle {
  background-color: #444;
  color: #ffffff;
}

.dark-mode .dropdown-menu {
  background-color: #333;
  color: #ffffff;
  max-height: 100px;
  overflow-y: scroll;
}

.dark-mode .nav-link.active {
  background-color: #444;
  color: #ffffff;
}

.dark-mode .app-header {
  background-color: #1e1e1e;
  color: #ffffff;
  border-bottom: 1px solid #333;
}

.dark-mode .btn-dark-mode {
  color: #ffffff;
}

/* Tabs */
.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-link {
  cursor: pointer;
  padding: 0.5rem 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: background-color 0.2s;
}

.nav-link.active {
  color: #495057;
  border-color: #dee2e6;
}

/* Dropdown */
.dropdown-toggle {
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Center items vertically */
  justify-content: flex-start; /* Align items to the start */
  cursor: pointer;
  border: 1px solid transparent;
  min-height: 38px; /* Adjust as needed */
}

.dropdown-menu {
  border-radius: 0.25rem;
  padding: 0.5rem 0;
}

.dropdown-item {
  padding: 0.5rem 1rem;
  transition: background-color 0.2s, color 0.2s;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
  color: #000;
}

.dropdown-item:focus {
  background-color: #e0e0e0;
  color: #000;
}

/* Dark mode dropdown items */
.dark-mode .dropdown-item {
  background-color: #333;
  color: #ffffff;
}

.dark-mode .dropdown-item:hover {
  background-color: #444;
  color: #ffffff;
}

.dark-mode .dropdown-item:focus {
  background-color: #555;
  color: #ffffff;
}

/* Button for toggling dark mode */
.btn-dark-mode {
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: inherit;
  margin-left: auto;
}

/* Additional styles for sports content */
.sports-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 0.5rem;
}

.provider-container-header {
  display: flex;
  align-items: center;
  gap: 10px; /* Small space between dropdown and button */
}

/* .submit-button {
  margin-top: 10px;
  background-color: #353333;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
} */

.sports-sections {
  display: flex;
  justify-content: space-between;
}

.sports-section {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 1rem;
  /* Add rounded corners */
  border-radius: 10px; 
  overflow: hidden; /* Ensures content stays within rounded corners */
  /* Optional: Add a border for better visibility */
  border: 1px solid #ccc;
}

.section-header {
  flex-shrink: 0; /* Ensures the header doesn’t shrink */
  margin-bottom: 0.5rem; /* Adjust as needed */
  /* Add rounded corners to the top */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #e9ecef; /* Optional: Add background color */
  padding: 0.5rem; /* Optional: Add padding */
  display: flex;
  justify-content: space-between;  /* Ensures spacing between header text and search icon */
  align-items: center;       /* Vertically centers the text and icon */
}

.section-content {
  overflow-y: auto; /* Scrollable content */
  flex: 1; /* Makes content area take up remaining space */
  max-height: 300px; /* Adjust this as needed */
  padding-top: 0.5rem; /* Optional padding to separate from header */
  padding-bottom: 0.5rem; /* Optional padding to separate from footer */
  /* Add rounded corners to the bottom */
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

/* Adjust dynamic and internal sections for dark mode */
.dynamic-section .section-header, .internal-section .section-header {
  background-color: #f9f9f9;
}

.dark-mode .dynamic-section .section-header, .dark-mode .internal-section .section-header {
  background-color: #1c1c1c;
}

.dynamic-section .section-content,
.internal-section .section-content {
  background-color: #f9f9f9;
  /* Add border radius to the content section */
  border-radius: 0 0 10px 10px;
}

.dark-mode .dynamic-section .section-content,
.dark-mode .internal-section .section-content {
  background-color: #2c2c2c;
  border: 1px solid #444;
  border-radius: 0 0 10px 10px;
}

.dark-mode .internal-section .section-content {
  background-color: #1c1c1c;
  border: 1px solid #333;
  border-radius: 0 0 10px 10px;
}


.sports-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.sport-item {
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.dark-mode .sport-item {
  background-color: #2c2c2c;
  border: 1px solid #444;
  color: #fff;
}

.sport-item h4 {
  margin: 0 0 0.5rem;
  font-size: 1.1rem;
}

.sport-item p {
  margin: 0;
  font-size: 1rem;
}

.sport-item.selected.dark-mode {
  background-color: #27415d;
  color: #fff;
  border: 1px solid #27415d;
}

.sport-item.selected.light-mode {
  background-color: #0056b3;
  color: #fff;
  border: 1px solid #0056b3;
}

.sport-item.mapped.dark-mode {
  background-color: #275d38;
  color: #fff;
  border: 1px solid #275d38;
  pointer-events: none;
}

.sport-item.mapped.light-mode {
  background-color: #006b3c;
  color: #fff;
  border: 1px solid #006b3c;
  pointer-events: none;
}

/* Linked section styles */
.linked-section {
  margin-top: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: var(--linked-background-color);
  max-height: 300px; /* Adjust this as needed */
  display: flex;
  flex-direction: column;
}

.linked-section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.dark-mode .linked-section-header {
  background-color: #1c1c1c;
  border-bottom: 1px solid #444;
}

.linked-section-content {
  padding: 10px;
  overflow-y: auto;
  flex-grow: 1; /* Makes the content area fill the remaining space */
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}


.linked-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  justify-content: space-between;
}

.linked-item.scheduled.dark-mode{
  background-color: #5d272b;
  color: #fff;
  border: 1px solid #5d272b;
}

.linked-item.scheduled.light-mode{
  background-color: #b3001b;
  color: #fff;
  border: 1px solid #b3001b;
}

.dark-mode .linked-item {
  background-color: #2c2c2c;
  border: 1px solid #444;
}

.linked-item-details {
  display: flex;
  gap: 10px;
}

.linked-item-actions {
  display: flex;
  gap: 10px;
}

.linked-item-actions button {
  background: transparent;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 1rem;
}

.linked-item-actions button:hover {
  color: #0056b3;
}

.save-edit-button {
  margin-top: 10px;
  background-color: #27415d;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.save-edit-button:hover {
  background-color: #27415d;
}

.add-link-button {
  margin-top: 10px;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: all 0.3s ease; /* Smooth transitions */
}

.add-link-button.dark-mode {
  background-color: #353333;
  color: #fff;
}

.add-link-button.light-mode {
  background-color: #e0e0e0;
  color: #000;
}

.add-link-button:disabled {
  cursor: not-allowed;
}

.add-link-button:hover {
  box-shadow: 0 0 0 1px #13653f; /* Add shadow to simulate a border */
}

.search-button {
  margin-top: 10px;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: all 0.3s ease; /* Smooth transitions */
}

.search-button.dark-mode {
  background-color: #353333;
  color: #fff;
}

.search-button.light-mode {
  background-color: #e0e0e0;
  color: #000;
}

.search-button:disabled {
  cursor: not-allowed;
}

.search-button:hover {
  box-shadow: 0 0 0 1px #13653f; /* Add shadow to simulate a border */
}

.delete-existing-link-button {
  margin-top: 10px;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: all 0.3s ease; /* Smooth transitions */
}

.delete-existing-link-button.dark-mode {
  background-color: #353333;
  color: #fff;
}

.delete-existing-link-button.light-mode {
  background-color: #e0e0e0;
  color: #000;
}

.delete-existing-link-button:disabled {
  cursor: not-allowed;
}

.delete-existing-link-button:hover {
  box-shadow: 0 0 0 1px #13653f; /* Add shadow to simulate a border */
}

.submit-button {
  margin-top: 10px;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: all 0.3s ease; /* Smooth transitions */
}

.submit-button.dark-mode {
  background-color: #353333;
  color: #fff;
}

.submit-button.light-mode {
  background-color: #e0e0e0;
  color: #000;
}

.submit-button:disabled {
  cursor: not-allowed;
}

.submit-button:hover {
  box-shadow: 0 0 0 1px #13653f; /* Add shadow to simulate a border */
}

/* Dark theme modal */
.modal-dark .modal-content {
  background-color: #353333 !important;
  color: #fff !important;
}

/* Light theme modal */
.modal-light .modal-content {
  background-color: #fff !important;
  color: #000 !important;
}

/* Optionally style the header and footer for consistency */
.modal-dark .modal-header, 
.modal-dark .modal-footer {
  background-color: #353333 !important;
  color: #fff !important;
}

.modal-light .modal-header, 
.modal-light .modal-footer {
  background-color: #f8f9fa !important;
  color: #000 !important;
}

/* Login styles */
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  transition: background-color 0.3s, color 0.3s;
}

.login-container h2 {
  margin-bottom: 20px;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.login-form .form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
}

.login-form label {
  font-weight: bold;
}

.login-form input {
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  max-width: 300px;
}

.login-container.light-mode {
  background-color: #f5f5f5;
  color: #000;
}

.login-container.dark-mode {
  background-color: #121212;
  color: #fff;
}

.form-control.dark-mode{
  background-color: #555;
  color: #fff;
}

/* force the search list to the left of the modal */
.search-list {
  display: flex;
  flex-direction: column;
  max-height: 500px;
  overflow-y: scroll;
}

.search-item {
  display: flex;
  align-items: left; /* Center items vertically */
  justify-content: space-between; /* Space between ID and Name */
  padding: 1rem;
  border: 1px solid #ccc;
  cursor: pointer;
  margin-bottom: 0.5rem; /* Add some space between rows */
  flex-direction: column;
}

.search-item.dark-mode {
  background-color: #121212;
  color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.search-item.selected {
  background-color: #27415d;
  color: #fff;
  border: 1px solid #0056b3;
}

.search-item.mapped {
  background-color: rgb(7, 71, 7);
  color: #fff;
  border: 1px solid rgb(7, 71, 7);
  pointer-events: none;
}

.search-bar-container{
  padding-bottom: 10px;
}

.loading-bubbles {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-right: 5px; /* Add space between bubbles and text */

}

.bubble {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 50%;
  animation: pulse 0.6s infinite alternate;
}

.bubble.dropdown {
  width: 7px;
  height: 7px;
  margin: 0 5px;
  border-radius: 50%;
  animation: pulse 0.6s infinite alternate;
}

.bubble.dark-mode {
  background-color: #fff; /* Change color as needed */
}

.bubble.light-mode {
  background-color: #000000; /* Change color as needed */
}

.bubble:nth-child(2) {
  animation-delay: 0.2s;
}

.bubble:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes pulse {
  0% {
      transform: scale(1);
  }
  100% {
      transform: scale(1.5);
  }
}

.market {
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: 1px solid #ccc; /* Adds a border around each market section */
  border-radius: 8px; /* Rounds the corners */
  padding: 12px; /* Adds some padding inside the market section */
  transition: all 0.3s ease; /* Smooth transition for collapse/expand effect */
}

.marketType {
  font-weight: bold;
  margin-bottom: 4px;
}

.teams {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #ddd; /* Line separating team names */
  padding-top: 8px;
}

.team {
  flex: 1;
}

.odds-values {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #ddd; /* Line separating odds and values */
  padding-top: 8px;
}

.odds-value {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.collapsible-header {
  cursor: pointer;
  padding: 8px;
  display: center;
  justify-content: space-between;
}

.collapsible-content {
  display: none; /* Hidden by default */
  overflow: hidden;
}

.collapsible-content.expanded {
  display: block; /* Shows when expanded */
}

.market-item {
  border: 1px solid #ddd; /* Light border for separation */
  border-radius: 5px; /* Rounded corners */
  margin-bottom: 10px; /* Space between items */
  padding: 10px;
}

.search-item-header {
  font-weight: bold; /* Emphasize header */
  margin-bottom: 5px; /* Space below header */
}

.login-page-buttons{
  display: flex;
  justify-content: space-between;
  width: 75%;
}

.reset-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.reset-form .form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
}

.reset-form label {
  font-weight: bold;
}

.reset-form input {
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  max-width: 300px;
}

.reset-form button {
  margin-bottom: 10%;
}

/* Grey out background when ErrorModal is open */
.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent grey */
  z-index: 1050; /* Make sure it covers the modal content */
  pointer-events: none; /* Prevent interactions with the overlay */
}